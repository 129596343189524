import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

function getChangeDirection(changePercent) {
  if (changePercent === 0) {
    return 'none';
  }

  return changePercent > 0 ? 'up' : 'down';
}

const changeIconMap = {
  up: faCaretUp,
  down: faCaretDown
};

function TickerItem({ quote }) {
  const { symbol, display_name: displayName, change_percent: changePercent } = quote;

  const changeDirection = getChangeDirection(changePercent);
  const changeIcon = changeIconMap[changeDirection] || null;

  return (
    <div className="ticker__item">
      <span className={`stock-quote stock-quote-${symbol} stock-quote-change-${changeDirection}`}>
        <span className="stock-quote__name">{displayName} </span>
        <span className="stock-quote__change-percent">
          {`${(changePercent * 100).toFixed(2)}%`}
          {changeIcon && <>{' '}<FontAwesomeIcon icon={changeIcon} /></>}
        </span>
      </span>
    </div>
  );
}

export default TickerItem;
