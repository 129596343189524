import styled from 'styled-components';

const TickerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 3rem;
  background-color: #000;
  z-index: 1000;

  .ticker-title {
    color: white;
    padding: 0.1875rem 0;

    .ticker-title-inner {
      padding: 0 1.25rem 0 2.5rem;
      height: 100%;
    }

    .logo-tastyworks {
      height: 0.8rem;
      width: auto;
    }

    h6 {
      margin: 0;
      letter-spacing: 0.075em;
      font-weight: bold;
    }
  }
`;

export default TickerWrapper;
