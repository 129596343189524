import axios from 'axios';
import Cookies from 'js-cookie';
import {
  LOGIN_AUTHENTICATOR,
  LOGIN_COOKIE_DOMAIN,
  LOGIN_COOKIE_KEY,
  LOGIN_SUCCESS_URL,
  TASTYWORKS_API,
} from 'utils/constants';

class TastyworksApi {
  static defaultOptions = {
    cookieKey: LOGIN_COOKIE_KEY,
    cookieAttributes: { domain: LOGIN_COOKIE_DOMAIN },
    authenticator: LOGIN_AUTHENTICATOR,
    loginSuccessUrl: LOGIN_SUCCESS_URL,
  };

  constructor(apiHost = TASTYWORKS_API, options = {}) {
    this.options = {
      ...TastyworksApi.defaultOptions,
      ...options
    };

    this.client = axios.create({
      baseURL: apiHost
    });
  }

  getSessionToken() {
    const raw = Cookies.get(this.options.cookieKey);
    if (!raw) {
      return null;
    }

    try {
      const json = JSON.parse(raw);
      return json.authenticated.data['session-token'];
    } catch (e) {
      return null;
    }
  }

  removeSessionToken() {
    Cookies.remove(this.options.cookieKey, this.options.cookieAttributes);
  }

  async validateSession() {
    const sessionToken = this.getSessionToken();
    if (!sessionToken) {
      this.removeSessionToken();

      return false;
    }

    const headers = { Authorization: sessionToken };

    try {
      await this.client.post('sessions/validate', null, { headers });

      return true;
    } catch (error) {
      this.removeSessionToken();

      return false;
    }
  }

  async logout() {
    const sessionToken = this.getSessionToken();
    if (!sessionToken) {
      return false;
    }

    const headers = { Authorization: sessionToken };

    try {
      await this.client.delete('sessions', { headers });
    } finally {
      this.removeSessionToken();

      /* global window */
      if (window) {
        window.location.reload();
      }
    }

    return true;
  }
}

// Create instance of TastyworksApi with default options to be exported.
const tastyworksApi = new TastyworksApi();

export default tastyworksApi;
