export const DESKTOP_VERSION = process.env.DESKTOP_VERSION || '0.41.0';
export const TASTYWORKS_API = process.env.TASTYWORKS_API || 'https://api.tastyworks.com/';
export const LOGIN_SUCCESS_URL = process.env.LOGIN_SUCCESS_URL || 'https://my.tastytrade.com';
export const STOCK_TICKER_API_URL = process.env.STOCK_TICKER_API_URL;
export const LOGIN_COOKIE_DOMAIN = '.tastytrade.com';
export const LOGIN_COOKIE_KEY = 'ember_simple_auth-session';
export const LOGIN_AUTHENTICATOR = 'authenticator:twucs';
export const SUPPORT_EMAIL = 'support@tastytrade.com';
export const SUPPORT_PHONE = '888-247-1963';
export const FRESHCHAT_HOST = 'https://wchat.freshchat.com';
export const FRESHCHAT_TOKEN = 'ddb8f362-46f4-4536-a634-e2911424f6ed';
export const ONETRUST_DOMAIN_ID = process.env.GATSBY_ONETRUST_DOMAIN_ID || 'f201e8ed-d81f-4143-9444-07756e116e4b-test';
export const NAV_BREAKPOINT = 991;

export const BRIGHTCOVE_SETTINGS = {
  brightcoveAccount: '6057994532001',
  brightcoveAdConfigId: 'live.G57ynp2X7rLQN5tEU-Ebvpf8putgam4-U_EAW1rDYzH9bpUpIBkkSEq2fbJxbhNy9_zxsrZYfjz2-vcwudnIHTGvEEaFT-iUAyrK9gqM',
  brightcoveApplicationId: '',
  brightcoveEmbed: 'default',
  brightcoveVideoId: '6213174879001',
  brightcovePlayerId: 'TOAkQJPlM',
  defaultVolume: 0.8,
}

export const MAILCHIMP_FORM_URL = 'https://tastyworks.us14.list-manage.com/subscribe/post?u=d1f46bc08ad07a3549e29cbc4&amp;id=112811d820';

export const DEVICE_VISIBILITY = {
  mobile_only: 'mobile-only',
  desktop_only: 'desktop-only'
}
