// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-opening-management-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/account-opening-management.js" /* webpackChunkName: "component---src-pages-account-opening-management-js" */),
  "component---src-pages-accounts-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/accounts.js" /* webpackChunkName: "component---src-pages-accounts-js" */),
  "component---src-pages-appreciation-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/appreciation.js" /* webpackChunkName: "component---src-pages-appreciation-js" */),
  "component---src-pages-challenge-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/challenge.js" /* webpackChunkName: "component---src-pages-challenge-js" */),
  "component---src-pages-commission-and-fees-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/commission-and-fees.js" /* webpackChunkName: "component---src-pages-commission-and-fees-js" */),
  "component---src-pages-contact-us-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclosures-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/disclosures.js" /* webpackChunkName: "component---src-pages-disclosures-js" */),
  "component---src-pages-education-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-external-complaints-policy-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/external-complaints-policy.js" /* webpackChunkName: "component---src-pages-external-complaints-policy-js" */),
  "component---src-pages-forms-and-agreements-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/forms-and-agreements.js" /* webpackChunkName: "component---src-pages-forms-and-agreements-js" */),
  "component---src-pages-how-to-fund-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/how-to-fund.js" /* webpackChunkName: "component---src-pages-how-to-fund-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-referral-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-rule-606-routing-reports-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/rule-606-routing-reports.js" /* webpackChunkName: "component---src-pages-rule-606-routing-reports-js" */),
  "component---src-pages-security-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-support-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tax-information-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/tax-information.js" /* webpackChunkName: "component---src-pages-tax-information-js" */),
  "component---src-pages-technology-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-withdrawals-and-transfers-js": () => import("/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/src/pages/withdrawals-and-transfers.js" /* webpackChunkName: "component---src-pages-withdrawals-and-transfers-js" */)
}

