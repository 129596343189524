require('typeface-open-sans-condensed');
require('typeface-open-sans');
require('./src/index.scss');
require('react-responsive-carousel/lib/styles/carousel.min.css');

// Solution to Resolve known issue with Page scrolling to top when using Gatsby
// + react-scrollable-anchor: https://github.com/gatsbyjs/gatsby/issues/11898

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  if (location.hash) {
    return false;
  }

  return true;
};
