import styled, { keyframes } from 'styled-components';
import { prop } from "styled-tools"

const duration = '60s';
const delay = '0s';

const tickerKeyframes = keyframes`
  0% {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
`;

const Ticker = styled.div`
  width: 100%;
  overflow: hidden;
  height: 3rem;
  box-sizing: content-box;
  z-index: 1000;
  white-space: nowrap;

  .ticker,
  .ticker-clone {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    box-sizing: content-box;
    animation: ${tickerKeyframes} ${duration} linear infinite;
    animation-delay: ${delay};

    .ticker__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 0.9rem;
      font-family: ${prop('theme.fonts.default')};
      font-weight: bold;
      color: white;

      .stock-quote-change-up {
        color: #269932;
      }

      .stock-quote-change-down {
        color: #e31f26;
      }

      .fas {
        margin-left: 0.46875rem;
      }
    }
  }

  .ticker-clone {
    animation-name: ${tickerKeyframes};
  }
`;

export default Ticker;
