module.exports = [{
      plugin: require('/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WB4TWN5","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"}},
    },{
      plugin: require('/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-88655934-2","head":true},
    },{
      plugin: require('/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/static-marketing-australia-react/static-marketing-australia-react/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
