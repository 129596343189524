import React, { useEffect } from 'react';
import { ContextProviderComponent } from 'components/Context';
import { LayoutProvider } from 'context/LayoutContext';
import StockTicker from 'components/StockTicker';

/**
 * Note that this file must remain src/layouts/index.js
 * in order for the gatsby-plugin-layout plugin to work
 * properly.
 *
 * If it needs to change, then the config for that plugin
 * will need to be updated to point to the new file location.
 *
 * @see https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
function WrappingLayout({ children }) {
  useEffect(() => {
    const listener = (event) => {
      const zvaElemId = 'zva-script';
      if (document.scrollingElement.scrollTop >=200 &&
          !document.getElementById(zvaElemId)) {

        let zvaScriptElem = document.createElement('script');
        zvaScriptElem.id = zvaElemId;
        zvaScriptElem.async = true;
        zvaScriptElem.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js';
        zvaScriptElem.setAttribute('data-apikey', '1SzzftE4Tx2wM3XUSkcleA');
        zvaScriptElem.setAttribute('data-env', 'us01');

        document.body.appendChild(zvaScriptElem);

        let updateChatWidgetElem = document.createElement('script');
        updateChatWidgetElem.src = '/updateChatWidget.js';
        document.body.appendChild(updateChatWidgetElem);
      }};
    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    }
  }, []);

  return (
    <ContextProviderComponent>
      <LayoutProvider>
        {children}
      </LayoutProvider>
    </ContextProviderComponent>
  );
}

export default WrappingLayout;
