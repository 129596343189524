import React, { useReducer, useContext, useState, useEffect } from 'react';

function getDefaultState() {
  const DEFAULT_STATE = {
    showAlert: false,
  };

  let storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_SET_SHOW_ALERT = 'setShowAlert';

function layoutReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: action.payload,
      }
    default:
      return state;
  }
}

const LayoutContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  setShowAlert: (showAlert) => {},
});

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    layoutReducer,
    getDefaultState()
  );

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    setShowAlert: (showAlert) => dispatch({ type: ACTION_SET_SHOW_ALERT, payload: showAlert })
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state
    }));
  }, [state]);

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
